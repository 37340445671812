<template>
  <div class="upload">
 
    <el-upload
      :action="uploadOptions.url"
      :headers="uploadOptions.header"
      class="avatar-uploader"
      name="file"
      accept="image/png, image/jpeg"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :style="`width: ${width}; height: ${height}`"
    >
      <img v-if="imgUrl.absoluteUrl" :src="imgUrl.absoluteUrl" class="avatar" :style="`width: ${width}; height: ${height}`">
      <i v-else class="el-icon-picture-outline avatar-uploader-icon" />
    </el-upload>
  </div>
</template>

<script>
  import { getStore } from '@/utils/store'
  class UploadOptions {
    url = process.env.VUE_APP_BASE_API + 'admin/file/save';
    header = {
      'Auth-Key': getStore('authKey') ? getStore('authKey') : '',
      'Session-Id': getStore('sessionId') ? getStore('sessionId') : '',
      'Platform': 'pc'
    }
  }
  class Img {
    absoluteUrl = '';
    relativeUrl = '';
  }
  export default {
    name: 'ImgUploadOne',
    props: {
      existUrl: {
        type: Object,
        default: () => {
          return {
            absoluteUrl: '',
            relativeUrl: ''
          };
        }
      },
      width: {
        type: String,
        default: '146px'
      },
      height: {
        type: String,
        default: '146px'
      },
     
    },
    data() {
      return {
        uploadOptions: new UploadOptions(),
        imgUrl: new Img() // 图片
      };
    },
    watch: {
      existUrl(newVal) {
        // console.log(newVal);
        if (newVal) {
          this.imgUrl = this.existUrl;
        }
      }
    },
    mounted() {
      this.imgUrl = this.existUrl;
    },
    methods: {
      // 初始化图片显示
      open() {
        this.imgUrl = '';
      },
      // 图片上传校验
      beforeAvatarUpload(file) {
        // console.log(file);
        if (file.type.indexOf('image') == -1) {
          this.$message.error('请上传正确的图片格式');
          return false;
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          this.$message.error('上传凭证图片大小不能超过10MB!');
          return false;
        }
      },
      // 上传图片成功回调
      handleAvatarSuccess(res) {
        // console.log(res);
        if (res) {
          const { data } = res;
          this.imgUrl = {
            absoluteUrl: data.file_path,
            relativeUrl: data.save_name
          };
          this.$emit('setUrl', this.imgUrl);
        } else {
          this.$message.info(res.msg);
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  $img_width: 146px;
  $img_height: 146px;
  .avatar-uploader ::v-deep.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader ::v-deep.el-upload:hover {
    border-color: #295394;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: $img_width;
    height: $img_height;
    line-height: $img_height;
    text-align: center;
  }
  .avatar {
    width: $img_width;
    height: $img_height;
    display: block;
  }
   .big {
    width: 300px;
    max-height: 600px;
    display: block;
    margin: 0 auto;
  }
</style>
