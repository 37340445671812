<template>
  <div class="user">
    <el-form :rules="userRules" :model="detail" label-width="100px" class="user-form" ref="detail">
      <el-form-item class="upImg">
        <img-upload width="100" height="100" :exist-url="existUrl" @setUrl="getUrl" />
        <span class="uploader">上传头像</span>
      </el-form-item>
      <el-form-item label="用户名:" prop="username">
        <el-input v-model="detail.username" disabled placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="旧密码:" prop="oldPass">
        <el-input
          v-model="detail.oldPass"
          clearable
          show-password
          type="password"
          placeholder="请输入原密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码:" prop="newPass">
        <el-input
          v-model="detail.newPass"
          clearable
          show-password
          type="password"
          placeholder="请输入新密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码:" prop="confirmPass">
        <el-input
          v-model="detail.confirmPass"
          clearable
          show-password
          type="password"
          placeholder="请输入新密码"
        ></el-input>
      </el-form-item>
      <el-button class="submit" type="success" @click="submitForm('detail')">提交</el-button>
    </el-form>
  </div>
</template>

<script>
import imgUpload from "@/components/Upload/imgUploadOne";
import { updateAdminSysUser, getAdminSysUserInfo } from "@/api/sys";
export default {
  name: "user",
  components: {
    imgUpload
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.detail.confirmPass !== "") {
          this.$refs.detail.validateField("confirmPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.detail.newPass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      detail: {
        img: "",
        thumb_img: "",
        username: "",
        oldPass: "",
        newPass: "",
        confirmPass: ""
      },
      userRules: {
        username: [
          { required: true, message: "请填写用户名", trigger: "blur" }
        ],
        oldPass: [{ required: true, message: "请填写旧密码", trigger: "blur" }],
        newPass: [
          { required: true, message: "请填写新密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" }
        ],
        confirmPass: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" }
        ]
      },
      existUrl: {
        absoluteUrl: "",
        relativeUrl: ""
      }
    };
  },
  created() {
    this.getData();
  },
  destroyed() {},
  methods: {
    // 获取基本数据
    getData() {
      getAdminSysUserInfo().then(res => {
        this.detail = res.data;
        this.existUrl.absoluteUrl = res.data.img;
        this.existUrl.relativeUrl = res.data.thumbImg;
      });
    },
    // 上传图片
    getUrl(url) {
      const { absoluteUrl, relativeUrl } = url;
      this.detail.img = absoluteUrl;
      this.detail.thumb_img = relativeUrl;
    },
    // 提交
    submitForm(detail) {
      this.$refs[detail].validate(valid => {
        if (valid) {
          this.hint();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提示
    hint() {
      this.$confirm(`确定更新此账户信息吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确认提交
    confirm() {
      updateAdminSysUser({ ...this.detail }).then(() => {
        this.$alert("请退出重新登陆", "修改成功", {
          confirmButtonText: "确定",
          showClose: false,
          callback: () => {
            this.$store.dispatch("logout").then(() => {
              this.$router.push("/login");
            });
          }
        });
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.user-form {
  width: 500px;
  text-align: center;
  margin: 50px auto 0;
  .uploader {
    font-size: 12px;
    color: $theme-color;
  }
  .submit {
    @include successBtn(100px);
    margin-left: 50px;
  }
}
.upImg {
  text-align: center;
}
::v-deep .avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: $theme-color;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
